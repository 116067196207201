import React, { Component } from 'react';
import Helmet from 'react-helmet';

import downloadMac from '../images/download_mac.svg';
import downloadWin from '../images/download_windows.png';
import config from '../config';
import { macOrWindows, isNotWin10 } from '../utils';

const supportGumroad = false;

class DownloadBadge extends Component {
  constructor(props) {
    super(props);
    this.system = macOrWindows();
    this.notWin10 = isNotWin10();
  }

  track = (type) => {
    fbq('track', 'Purchase', {
      content_ids: [type],
      value: 2.99,
      currency: 'USD',
      content_type: 'product',
    });
  }

  render() {
    let template;
    // to prevent ssr
    if (typeof window === 'undefined') {
      return null;
    }

    if (this.system === 'mac') {
      template = (
        <div>
          <a href={config.macLink} target="_blank" rel="noopener noreferrer" onClick={() => this.track('mac')}>
            <img className="badge" src={downloadMac} alt="download from mac app store" />
          </a>
          <a href={config.winLink} target="_blank" rel="noopener noreferrer" onClick={() => this.track('win')}>
            <img className="badge win" src={downloadWin} alt="download from windows store" />
          </a>
        </div>
      );
    } else if (this.system === 'win') {
      template = (
        <div>
          {this.notWin10 && supportGumroad
            ? (
              <React.Fragment>
                <Helmet defer={false}>
                  <script src="https://gumroad.com/js/gumroad.js" />
                </Helmet>
                <a className="gumroad-button mr2" href="https://gum.co/evertool?wanted=true" target="_blank" data-gumroad-single-product="true" rel="noopener noreferrer">
                  Download Windows
                </a>
              </React.Fragment>
            )
            : (
              <a href={config.winLink} target="_blank" onClick={() => this.track('win')} rel="noopener noreferrer">
                <img className="badge win" src={downloadWin} alt="download from windows store" />
              </a>
            )
          }
          <a href={config.macLink} target="_blank" onClick={() => this.track('mac')} rel="noopener noreferrer">
            <img className="badge" src={downloadMac} alt="download from mac app store" />
          </a>
        </div>
      );
    }
    return (
      <div>
        {template}
        {/* <div className="seven-day">
          <FormattedMessage id="7day"/>
        </div> */}
      </div>
    );
  }
}

export default DownloadBadge;
